import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  IconActions,
  IconDelete,
  IconConfig,
  IconPassword,
  IconReset,
  Modal,
} from 'shared/components';
import './styles.scss';

const LicenseUsersActionMenu = ({
  user,
  handleRemoveLicenseUser,
  handleMaxActivationsChange,
  handleResetTotalActivations,
  handlePasswordChange,
}) => {
  const { t } = useTranslation();
  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="LicenseUsersActionMenuBtn" onClick={e => e.stopPropagation()} role="button" tabIndex={0}>
      <button
        className="table-select"
        aria-label="Select license"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleMenu();
        }}
        type="button"
      >
        <IconActions stroke="#2e86de" />
      </button>
      {isMenuDisplayed && (
        <Modal confirmCb={() => {}} hideFooter closeCb={toggleMenu} size="sm">
          <div className="LicenseUsersActionMenu">
            <div className="header">
              <Label text={t('License user')} />
              <div className="text">{get(user, 'true_email')}</div>
            </div>
            <ul className="inner-menu">
              <li>
                <Button
                  className="reset-button"
                  onClick={() => handlePasswordChange()}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconPassword height="22px" width="22px" viewBox="0 0 65 60" />
                    </div>
                    <div>{t('Change password')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  className="reset-button"
                  onClick={() => handleMaxActivationsChange(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconConfig width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#555" strokeWidth="2" strokeLinecap="square" color="#555" />
                    </div>
                    <div>{t('Set max activations')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  className="reset-button"
                  onClick={() => handleResetTotalActivations(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconReset
                        fill="none"
                        stroke="#555"
                        strokeWidth="2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      />
                    </div>
                    <div>{t('Reset total activations')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  className="reset-button"
                  onClick={() => handleRemoveLicenseUser(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconDelete height="16px" width="16px" color="#ee5253" />
                    </div>
                    <div>{t('Remove')}</div>
                  </>
                </Button>
              </li>
            </ul>
          </div>
        </Modal>
      )}
    </div>
  );
};

LicenseUsersActionMenu.propTypes = {
  user: PropTypes.object.isRequired,
  handleRemoveLicenseUser: PropTypes.func.isRequired,
  handleMaxActivationsChange: PropTypes.func.isRequired,
  handleResetTotalActivations: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
};

export default LicenseUsersActionMenu;
