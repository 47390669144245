import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  List,
  DescriptionTable,
  JsonView,
  IconPopupOpen,
  Button,
  Notification,
} from 'shared/components';
import { useAPI } from 'shared/hooks';
import {
  displayValue,
  capitalizeFirstLetter,
  formatDate,
  formatOrderBy,
} from 'shared/helpers';
import {
  productFeatureTypes,
  defaultDateFormat,
} from 'shared/constants';
import LicenseFeatureDevices from './LicenseFeatureDevices';
import './styles.scss';

const PlatformFeatures = ({
  license,
}) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');
  const [tableExpanded, setTableExpanded] = useState({});
  const [page, setPage] = useState(0);
  const [currentSort, setCurrentSort] = useState([{ desc: true, id: 'last_check' }]);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [showDevicesPopup, setDevicesPopupDisplay] = useState(false);
  const [devicePopupFeature, setDevicePopupFeature] = useState(null);

  const { isLoading, value: list = {} } = useAPI({
    url: `/api/v1/license-product-features/?license=${licenseID}&limit=20&offset=${page * 20}${formatOrderBy(currentSort)}`,
    onError: () => Notification('error', t('Error occured'), t('There was an error while getting license features')),
    dependenciesArray: [licenseID, page, currentSort, refetchCounter],
  });

  const handlePageChange = newPage => setPage(newPage);

  const handleSortChange = newSort => setCurrentSort(newSort);

  return (
    <div className="ProductFeatures">
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => {
          const isFloatingFeature = get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud');
          const floatingTimeout = get(row, 'original.floating_timeout') || 120;
          const isConsumption = get(row, 'original.product_feature.feature_type') === 'consumption';
          const allowOverages = get(row, 'original.allow_overages');
          const resetConsumption = get(row, 'original.reset_consumption');

          return (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  // consumption data
                  {
                    label: isConsumption ? t('Max consumptions') : null,
                    value: displayValue(get(row, 'original.max_consumption')),
                  },
                  {
                    label: (isConsumption && allowOverages) ? t('Max overages') : null,
                    value: displayValue(get(row, 'original.max_overages')),
                  },
                  {
                    label: (isConsumption && resetConsumption) ? t('Reset consumptions') : null,
                    value: displayValue(capitalizeFirstLetter(get(row, 'original.consumption_period')), t('N/A')),
                  },
                  // floating licenses data
                  {
                    label: get(row, 'original.is_floating') ? t('Offline floating feature') : null,
                    value: get(row, 'original.is_floating') ? t('Yes') : t('No'),
                  },
                  {
                    label: get(row, 'original.is_floating_cloud') ? t('Is floating cloud') : null,
                    value: get(row, 'original.is_floating_cloud') ? t('Yes') : t('No'),
                  },
                  {
                    label: isFloatingFeature ? t('Max simultaneous license users') : null,
                    value: displayValue(get(row, 'original.floating_users')),
                  },
                  {
                    label: isFloatingFeature ? t('Floating timeout') : null,
                    value: `${displayValue(floatingTimeout)} min`,
                  },
                  // {
                  //   label: canBorrow ? t('Can borrow') : null,
                  //   value: canBorrow ? t('Yes') : t('No'),
                  // },
                  // {
                  //   label: canBorrow ? t('Max borrow time') : null,
                  //   value: `${displayValue(get(feature, 'max_borrow_time'))} ${t('hours')}`,
                  // },
                  {
                    label: t('Metadata'),
                    value: (
                      <JsonView
                        value={get(row, 'original.metadata')}
                        name="metadata"
                      />
                    ),
                  },
                ]}
              />
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: t('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'product_feature.name',
            Header: t('Name'),
          },
          {
            accessor: 'product_feature.code',
            Header: t('Code'),
            width: 100,
          },
          {
            accessor: 'product_feature.feature_type',
            Header: t('Type'),
            Cell: cellData => displayValue(cellData.value),
            width: 100,
          },
          {
            accessor: 'total_consumptions',
            Header: t('Total consumptions'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const type = get(cellData, 'original.product_feature.feature_type');
              if (type === productFeatureTypes.activation) { return t('N/A'); }

              const value = get(cellData, 'value') || 0;
              const maxConsumptions = get(cellData, 'original.max_consumption');
              const allowOverage = get(cellData, 'original.allow_overages');
              const maxOverages = get(cellData, 'original.max_overages');
              const isUnlimited = get(cellData, 'original.allow_unlimited_consumptions');

              if (isUnlimited) {
                return `${value} / ${t('Unlimited')}`;
              }

              if (allowOverage) {
                const maxValue = Number(maxConsumptions) + Number(maxOverages);
                return `${value} / ${maxValue}`;
              }

              return `${value} / ${maxConsumptions}`;
            },
          },
          {
            accessor: 'floating_users',
            Header: t('Floating slots'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const isFloatingFeature = get(cellData, 'original.is_floating') || get(cellData, 'original.is_floating_cloud');
              if (!isFloatingFeature) {
                return 'N/A';
              }

              const maxUsers = get(cellData, 'value');
              const featureDevices = get(cellData, 'original.device_features') || [];
              const floatingDevicesInUse = featureDevices.reduce((acc, item) => {
                if (item.floating_in_use === true) {
                  return acc + 1;
                }
                return acc;
              }, 0);
              return (
                <div>
                  <span>{`${floatingDevicesInUse} / ${maxUsers}`}</span>
                </div>
              );
            },
            maxWidth: 120,
          },
          {
            accessor: 'device_features',
            Header: t('Devices'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const featureDevices = get(cellData, 'original.device_features') || [];
              return (
                <Button
                  className="table-button"
                  onClick={() => {
                    setDevicePopupFeature(get(cellData, 'original'));
                    setDevicesPopupDisplay(true);
                  }}
                  type="button"
                >
                  <div className="floating-slots-btn">
                    <span>{`${featureDevices.length}`}</span>
                    <IconPopupOpen
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </Button>
              );
            },
          },
          {
            accessor: 'product_feature.created_at',
            Header: t('Created on'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            maxWidth: 95,
          },
          {
            accessor: 'expiry_date',
            Header: t('Expiry date'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            width: 120,
          },
        ]}
        data={get(list, 'results') || []}
        pageSize={20}
        loading={isLoading}
        clickable={false}
        manual
        page={page}
        pages={list ? Math.ceil(list.count / 20) : 1}
        minRows={2}
        showPagination
        onPageChange={handlePageChange}
        onSortedChange={handleSortChange}
        defaultSorted={currentSort}
      />
      {showDevicesPopup && (
      <LicenseFeatureDevices
        feature={devicePopupFeature}
        close={() => {
          setDevicePopupFeature(null);
          setDevicesPopupDisplay(false);
        }}
        onFeatureDeviceRevoke={() => {
          setRefetchCounter(refetchCounter + 1);
          setDevicePopupFeature(null);
          setDevicesPopupDisplay(false);
        }}
      />
      )}
    </div>
  );
};

PlatformFeatures.propTypes = {
  license: PropTypes.object.isRequired,
};

export default PlatformFeatures;
